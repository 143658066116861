<template>
    <div class="index">
        <div class="index_top">
            <div class="index_top_left">客服数据分析</div>
            <div class="index_top_right">
                <div @click="back" class="back">返回主界面</div>
                <div class="index_top_right_img">
                    <img class="index_top_right_img" :src="headerImg" alt="">
                </div>
                <span class="index_top_right_text">{{username}}</span>
                <i @click="exit" style="cursor: pointer;width: 12px;height: 13px;" class="iconfont">&#xe698;</i>
            </div>
        </div>
        <div class="index_bottom">
            <router-view/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                headerImg: '',
                username: ''
            }
        },
        mounted() {
            this.username = localStorage.getItem("username");
            this.headerImg = localStorage.getItem("headerImg");
        },
        methods: {
            back() {
                this.$router.push({
                    path: '/student/competition/index'
                })
            },
            exit() {
                localStorage.removeItem("token");
                localStorage.removeItem("username");
                localStorage.removeItem("role");
                this.$message.success('退出登录成功！')
                this.$router.push({
                    path: '/'
                })
            }
        }
    }
</script>

<style scoped lang="scss">

    .index {
        width: 100%;
        height: 100vh;

        .index_top {
            height: 50px;
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1500);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px 0 20px;

            .index_top_left {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 500;
                color: #1E2226;
            }
            .index_top_right {
                display: flex;
                flex-direction: row;
                align-items: center;

                .back {
                    width: 128px;
                    height: 30px;
                    background-color: #F23B2F;
                    margin-right: 30px;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 30px;
                    color: #FFFFFF;
                    font-size: 14px;
                    cursor: pointer;
                }

                .index_top_right_img {
                    width: 32px;
                    height: 32px;
                    opacity: 1;
                    border: 1px solid #E6E7EB;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .index_top_right_text {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 500;
                    color: #696970;
                    margin-left: 8px;
                    margin-right: 22px;
                }
            }
        }
        .index_bottom {
            width: 100%;
            height: calc(100% - 50px);
        }
    }
</style>